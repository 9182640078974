import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
import State from '../../components/State';
import { Button, Dialog, Box, Text } from '@uchiru/components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The dialog component is used for all modals. It renders on top of the rest of the app with an overlay.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` You'll need to manage the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state in a wrapper component of your own. For documentation purposes only we've created a mock `}<inlineCode parentName="p">{`State`}</inlineCode>{` to handle this, but you should handle the state in the component you consume `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` in or in a wrapper component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={false}>
  {([isOpen, setIsOpen]) => (
    <>
      <Button onClick={() => setIsOpen(true)}>Open</Button>
      <Dialog isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <Dialog.Header>Title</Dialog.Header>
        <Box p={3}>
          <Text fontFamily="sans-serif">Some content</Text>
        </Box>
      </Dialog>
    </>
  )}
</State>
`}</code></pre>
    <p>{`You can also pass any non-text content into the header:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={false}>
  {([isOpen, setIsOpen]) => (
    <>
      <Button onClick={() => setIsOpen(true)}>Open</Button>
      <Dialog isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <Dialog.Header>
          <Octicon icon={Zap} />
        </Dialog.Header>
        <Box p={3}>
          <Text fontFamily="sans-serif">Some content</Text>
        </Box>
      </Dialog>
    </>
  )}
</State>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p><inlineCode parentName="p">{`Dialog`}</inlineCode>{` components get the `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` and `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{` categories of system props. `}<inlineCode parentName="p">{`Dialog.Header`}</inlineCode>{` components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, and `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set whether or not the dialog is shown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onDismiss`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A user-provided function that should close the dialog`}</td>
        </tr>
      </tbody>
    </table>
    <p><inlineCode parentName="p">{`Dialog.Header`}</inlineCode>{` does not take any non-system props.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      